<template>
  <!-- footer -->
  <!--  class="footer-container relative" :class="color" -->
  <section
    :class="{ ' color footer-container relative paddingBottom': paddingBottom, 'color footer-container  relative ': !paddingBottom}">
    <div class="footer-layout w-page-2xl">
      <div class="footer-links">
        <ul class="main-links">
          <router-link tag="li" v-for="link in mainLinks" :key="link.label" :to="link.route"
                       class="nav-link nav-main-link">
            {{ link.label }}
          </router-link>
        </ul>
        <ul class="other-links">
          <template v-for="link in otherLinks">
            <button class="nav-link" disabled="disabled" v-if="link.disabled === true"
                    :key="link.label">{{ link.label }}
            </button>
            <router-link :to="link.route" v-if="link.route && !link.disabled" :key="link.label"
                         class="nav-link">{{ link.label }}
            </router-link>
            <a :href="link.href" v-if="link.href && !link.disabled" :key="link.label"
               class="nav-link">{{ link.label }}</a>
            <button @click="link.action" v-if="link.action && !link.disabled" :key="link.label"
                    class="nav-link">{{ link.label }}
            </button>
          </template>
          <a href="javascript:openAxeptioCookies()" class="nav-link">Gestion des cookies</a>
        </ul>
        <div class="contact-data">
          <div>Lyon - France</div>
          <a href="mailto:info@lodylearn.fr">info@lodylearn.fr</a>
        </div>
        <div class="socials">
          <a :href="social.href" v-for="social in socials" :key="social.alt" class="nav-icon-link"
             target="_blank">
            <img :src="social.logo" :alt="social.alt"/>
          </a>
        </div>
      </div>
      <div class="footer-lodylearn">
        <img :src="lodylearn" alt="Logo Lodylearn"/>
        <span>© {{ (new Date()).getUTCFullYear() }} Lodylearn SAS</span>
      </div>
    </div>
  </section>
</template>

<script>

import Vue from 'vue';
import Component from 'vue-class-component';
import { resolve } from '../../_helpers/image.helper';

const facebook = resolve('rs/Icone-RS_FB_fond.svg');
const instagram = resolve('rs/Icone-RS_Insta_fond.svg');
const linkedin = resolve('rs/Icone-RS_Linkedin_fond.svg');
const youtube = resolve('rs/Icone-RS_Youtube_fond.svg');

@Component({
  name: 'default-footer3',
  props: {
    color: {
      type: String,
      default: 'bg-primary-800',
    },
    paddingBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lodylearn: resolve('logos/Lodylearn_Logo_white.svg'),
      socials: [
        // {
        //   alt: 'Facebook',
        //   href: 'https://facebook.com/lodylearn',
        //   logo: facebook,
        // },
        // {
        //   alt: 'Instagram',
        //   href: 'https://www.instagram.com/lordylearn_/?hl=fr',
        //   logo: instagram,
        // },
        // {
        //   alt: 'LinkedIn',
        //   href: 'https://fr.linkedin.com/company/lodylearn',
        //   logo: linkedin,
        // },
        // {
        //   alt: 'Youtube',
        //   href: 'https://www.youtube.com/channel/',
        //   logo: youtube,
        // },
      ],
      mainLinks: [
        {
          label: 'Lodylearn',
          route: { name: this.$routeNames.Platform },
        },
        {
          label: 'Modules',
          route: { name: this.$routeNames.Search },
        },
      ],
      otherLinks: [
        {
          label: 'Blog',
          href: 'https://blog.lodylearn.fr',
          disabled: true,
        },
        {
          label: 'Contact',
          route: { name: this.$routeNames.Contact },
        },
        {
          label: 'Lodylearn Business',
          href: 'https://business.lodylearn.fr',
          disabled: true,
        },
        {
          label: 'CGV',
          action: this.openCgv,
        },
        {
          label: 'Mentions légales',
          action: this.openLegals,
        },
        {
          label: 'Politique de confidentialité',
          action: this.openPrivacy,
        },
        {
          label: 'Handicap',
          action: this.openHandicap,
        },
        {
          label: 'Conditions d\'accessibilité',
          action: this.openAccessibility,
        },
      ],
    };
  },
  computed: {
    year() {
      return (new Date()).getUTCFullYear();
    },
  },
  methods: {
    async goto(direction) {
      await this.$router.push({ name: direction });
    },
    openCgv() {
      this.$router.push('/conditions-generales-de-vente');
      // this.$store.commit('globalModal/cgv/SET_OPEN', true);
    },
    openHandicap() {
      this.$router.push('/handicap');
      // this.$store.commit('globalModal/cgv/SET_OPEN', true);
    },
    openAccessibility() {
      this.$router.push('/conditions-d-accessibilite');
      // this.$store.commit('globalModal/cgv/SET_OPEN', true);
    },
    openLegals() {
      this.$router.push('/mentions-legales');
      // this.$store.commit('globalModal/legals/SET_OPEN', true);
    },
    openPrivacy() {
      this.$router.push('/politique-de-confidentialite');
      // this.$store.commit('globalModal/privacy/SET_OPEN', true);
    },
  },
})
export default class DefaultFooter3 extends Vue {
}
</script>

<style scoped>

</style>
